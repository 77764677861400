@font-face {
  font-family: "Metropolis Bold";
  src: url("/assets/fonts/Metropolis-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Regular";
  src: url("/assets/fonts/Metropolis-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Metropolis Regular Ita";
  src: url("/assets/fonts/Metropolis-RegularItalic.woff2") format("woff2");
  font-display: swap;
}

