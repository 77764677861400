.antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader{
  border-top-color: #0D569D;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner{
  0%{ -webkit-transform: rotate(0deg); }
  100%{ -webkit-transform: rotate(360deg); }
}

@keyframes spinner{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}

.btn-primary {
  @apply py-4 rounded-2 bg-brand-400 border border-brand-400 text-white text-20 font-700 hover:bg-white
  hover:text-brand-400 hover:border-tory disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-200
  transition-colors;
}

.btn-secondary {
  @apply bg-white rounded-2 border border-brand-400 py-4 text-20 text-brand-400 font-700 hover:border-white
  hover:bg-brand-400 hover:text-white active:bg-brand-100 active:text-brand-400 active:border-brand-400
  transition-colors;
}

.input-label {
  @apply block text-14 leading-144 mb-1;
}

.input-field {
  @apply w-full text-17 placeholder:text-gray-300 font-500 border border-gray-300 rounded-[6px] p-3
  focus:ring-0 focus:border-tory caret-brand-400 transition-colors scroll-mt-6;
}

.input-error-message {
  @apply block relative w-full bg-status-red-100 px-8 py-2 text-status-red-800 text-14 leading-111
  before:content-['!'] before:absolute before:left-2 before:w-4 before:h-4 before:bg-status-red-400
  before:rounded-full before:text-12 before:text-white before:font-700 before:text-center before:px-auto before:leading-122
}

.checkbox-wrapper {
	@apply block relative pl-8 mb-[6px] sm:mb-3 cursor-pointer select-none;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
	@apply absolute opacity-0 cursor-pointer w-0 h-0;
}

/* Create a custom checkbox */
.checkmark {
	@apply absolute top-0 left-0 w-6 h-6 rounded-1 border border-gray-300 hover:cursor-pointer;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked ~ .checkmark {
	@apply bg-brand-400 border border-brand-400;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
	@apply top-[5px] left-1 w-[15px] h-2 border-t-0 border-l-[3px] border-b-[3px] border-r-0 border-white rounded-[2px] -rotate-45;
}

/* Hide arrows of input type 'number': Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows of input type 'number': Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
