@media all and (-ms-high-contrast: none) and (min-width: 360px) {
  .sm\:container {
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;
  }

  .md\:container {
    max-width: 360px;
    margin-right: auto;
    margin-left: auto;
  }
}


@media all and (-ms-high-contrast: none) and (min-width: 768px) {
  .sm\:container {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
  }
  .md\:container {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (-ms-high-contrast: none) and (min-width: 1140px) {
  .sm\:container {
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }
  .md\:container {
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }
}
